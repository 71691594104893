<template>
  <ManagementClientDetail
    :client="client"
    :export-url="exportUrl"
    :breadcrumbs="breadcrumbs"
    @detail-program="
      $router.push({
        name: 'admin.clients-programs',
        params: { programId: $event },
      })
    "
  />
</template>

<script>
import ManagementClientDetail from "@/components/managements/clients/detail";

export default {
  name: "AdminClientsDetail",
  components: { ManagementClientDetail },
  computed: {
    breadcrumbs() {
      return [
        {
          label: "Manajemen Klien",
          href: "#/admin/clients",
          isCurrent: false,
        },
        {
          label: "Detail",
          isCurrent: true,
        },
      ];
    },
    client() {
      return this.$store.getters["admClients/clientById"](this.clientId);
    },
    exportUrl() {
      let baseUrl = this.$store.getters.axios.defaults.baseURL;
      let token = this.$store.state.auth.token;
      let params = new URLSearchParams();
      params.set("token", token);

      return `${baseUrl}/v1/admin/clients/${
        this.clientId
      }/export?${params.toString()}`;
    },
  },
  watch: {
    clientId: {
      immediate: true,
      handler() {
        this.$store.dispatch("admClients/getClientById", this.clientId);
      },
    },
  },
};
</script>

<style scoped></style>
