<template>
  <c-flex flex-dir="column" w="100%">
    <Breadcrumb :items="breadcrumbs" />
    <hr v-chakra w="100%" />
    <c-flex mt="20px">
      <c-text flex="1" font-size="3xl">Manajemen Klien</c-text>
      <c-button
        v-if="this['auth::role'] === 'super_admin'"
        type="button"
        variant="solid"
        variant-color="gray"
        color="primary.900"
        rounded="full"
        as="a"
        :href="exportUrl"
        :download="true"
        data-something="haha"
      >
        <c-image :src="require('@/assets/icon-file-export.svg')" />
        <c-text ml="5px" font-weight="500" font-size="16px">Export</c-text>
      </c-button>
      <c-button
        v-if="this['auth::role'] === 'super_admin'"
        type="button"
        variant="solid"
        variant-color="brand"
        color="white"
        rounded="full"
        ml="10px"
        @click="$emit('edit', client.id)"
      >
        <c-image :src="require('@/assets/icon-pencil.svg')" />
        <c-text ml="5px" font-weight="500" font-size="16px">Edit</c-text>
      </c-button>
    </c-flex>
    <c-flex mt="30px" flex-dir="column" v-if="client">
      <c-text font-size="xl">Data Pribadi</c-text>
      <hr v-chakra w="100%" mt="10px" />
      <c-flex mt="20px">
        <c-image
          :src="avatarUrl"
          h="156px"
          w="156px"
          object-fit="cover"
          rounded="full"
        />
        <c-flex ml="20px" flex-dir="column">
          <c-box>
            <c-text font-size="16px" font-weight="500" color="brand.900">
              Nama
            </c-text>
            <c-flex>
              <c-text font-size="24px" font-weight="700">
                {{ client.firstName }} {{ client.lastName }}
              </c-text>
              <c-text
                v-if="client.status === 'active'"
                color="#0075E1"
                bg="#0075E11A"
                border="1px solid #0075E1"
                rounded="full"
                w="fit-content"
                h="fit-content"
                px="10px"
                py="3px"
                ml="10px"
              >
                Aktif
              </c-text>
              <c-text
                v-else-if="client.status === 'pending'"
                bg="rgba(244, 204, 70, 0.2)"
                border="1px solid #F4CC46"
                color="#F4CC46"
                rounded="full"
                w="fit-content"
                h="fit-content"
                px="10px"
                py="3px"
                ml="10px"
              >
                Pending
              </c-text>
              <c-text
                v-else-if="client.status === 'done'"
                bg="rgba(136, 136, 136, 0.2)"
                border="1px solid #888888"
                color="#888888"
                rounded="full"
                w="fit-content"
                h="fit-content"
                px="10px"
                py="3px"
                ml="10px"
              >
                Selesai
              </c-text>
              <c-text
                v-else
                bg="#EA43351A"
                border="1px solid #EA4335"
                color="#EA4335"
                rounded="full"
                w="fit-content"
                h="fit-content"
                px="10px"
                py="3px"
                ml="10px"
              >
                Tidak aktif
              </c-text>
            </c-flex>
          </c-box>
          <c-box mt="10px">
            <c-text font-size="16px" font-weight="500" color="brand.900">
              Nomor Handphone
            </c-text>
            <c-text font-size="18px" font-weight="400">
              {{ client.phone }}
            </c-text>
          </c-box>
          <c-box mt="10px">
            <c-text font-size="16px" font-weight="500" color="brand.900">
              Email
            </c-text>
            <c-text font-size="18px" font-weight="400">
              {{ client.email }}
            </c-text>
          </c-box>
        </c-flex>
      </c-flex>
    </c-flex>
    <c-flex mt="30px" flex-dir="column" v-if="programs">
      <c-text font-size="xl">Riwayat Program</c-text>
      <hr v-chakra w="100%" />
      <c-flex mt="20px" grid-gap="20px" wrap="wrap">
        <c-box
          v-for="(item, index) in programs"
          :key="index"
          type="button"
          w="fit-content"
          h="fit-content"
          variant="ghost"
          p="0"
          m="0"
          cursor="pointer"
          @click="$emit('detail-program', item.id)"
        >
          <c-flex
            flex-dir="column"
            border="1px solid #F2F2F2"
            box-shadow="0px 5px 30px 0px #0000000D"
            p="20px"
            w="435px"
            h="132px"
            rounded="lg"
          >
            <c-flex w="100%" align-items="center">
              <c-text flex="1" font-size="xl" font-weight="500">
                {{ item.name }}
              </c-text>
              <c-text
                v-if="item.status === 'active'"
                color="#0075E1"
                bg="#0075E11A"
                border="1px solid #0075E1"
                rounded="full"
                w="fit-content"
                px="10px"
                py="3px"
              >
                Aktif
              </c-text>
              <c-text
                v-else-if="item.status === 'pending'"
                bg="rgba(244, 204, 70, 0.2)"
                border="1px solid #F4CC46"
                color="#F4CC46"
                rounded="full"
                w="fit-content"
                px="10px"
                py="3px"
              >
                Pending
              </c-text>
              <c-text
                v-else-if="item.status === 'done'"
                bg="rgba(136, 136, 136, 0.2)"
                border="1px solid #888888"
                color="#888888"
                rounded="full"
                w="fit-content"
                px="10px"
                py="3px"
              >
                Selesai
              </c-text>
              <c-text
                v-else
                bg="#EA43351A"
                border="1px solid #EA4335"
                color="#EA4335"
                rounded="full"
                w="fit-content"
                px="10px"
                py="3px"
              >
                Tidak aktif
              </c-text>
            </c-flex>
            <c-box mt="10px" v-if="item.endAt">
              <c-text font-size="md" color="brand.900">Berakhir pada</c-text>
              <c-text font-size="md">{{
                item.endAt | formatDate("DD MMMM YYYY")
              }}</c-text>
            </c-box>
          </c-flex>
        </c-box>
      </c-flex>
    </c-flex>
    <c-flex mt="30px" flex-dir="column" v-if="orders">
      <c-text font-size="xl">Riwayat Pembayaran</c-text>
      <hr v-chakra w="100%" />
      <c-flex mt="20px" grid-gap="20px" wrap="wrap">
        <c-flex
          v-for="(item, index) in orders"
          :key="index"
          flex-dir="column"
          border="1px solid #F2F2F2"
          box-shadow="0px 5px 30px 0px #0000000D"
          p="20px"
          w="435px"
          h="132px"
          rounded="lg"
        >
          <c-flex w="100%" align-items="center">
            <c-text flex="1" font-size="xl" font-weight="500">{{
              item.invoiceNumber
            }}</c-text>
          </c-flex>
          <c-box mt="10px">
            <c-text font-size="md"> Dibayarkan pada ... </c-text>
          </c-box>
          <c-box mt="5px">
            <c-text
              font-size="md"
              color="brand.900"
              v-if="item.status === 'done'"
            >
              Selesai
            </c-text>
            <c-text font-size="md" color="red.900" v-else>
              Belum selesai
            </c-text>
          </c-box>
        </c-flex>
      </c-flex>
    </c-flex>
    <c-flex mt="30px" flex-dir="column" v-if="coupons">
      <c-text font-size="xl">Riwayat Penggunaan Kupon</c-text>
      <hr v-chakra w="100%" />
      <c-flex mt="20px" grid-gap="20px" wrap="wrap">
        <c-flex
          v-for="(item, index) in coupons"
          :key="index"
          flex-dir="column"
          border="1px solid #F2F2F2"
          box-shadow="0px 5px 30px 0px #0000000D"
          p="20px"
          w="435px"
          h="132px"
          rounded="lg"
        >
          <c-flex w="100%" align-items="center">
            <c-text flex="1" font-size="xl" font-weight="500">{{
              item.name
            }}</c-text>
          </c-flex>
          <c-box mt="10px">
            <c-text font-size="md">
              Digunakan pada {{ item.usedAt | formatDate("DD MMMM YYYY") }}
            </c-text>
          </c-box>
          <c-box mt="5px">
            <c-text font-size="md" color="brand.900">
              – Rp {{ Number(item.discountValue).toLocaleString("id") }}
            </c-text>
          </c-box>
        </c-flex>
      </c-flex>
    </c-flex>
  </c-flex>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb";
export default {
  name: "ManagementClientDetail",
  props: ["breadcrumbs", "client", "exportUrl"],
  components: { Breadcrumb },
  data() {
    return {};
  },
  computed: {
    avatarUrl() {
      return this.client?.photoUrl ?? "/img/photo1.744938d0.png";
    },
    coupons() {
      return this.client?.coupons;
    },
    programs() {
      return this.client?.programs;
    },
    orders() {
      return this.client?.orders;
    },
  },
  watch: {
    clientId: {
      immediate: true,
      handler(clientId) {
        this.$store.dispatch("suManagementClient/getClientById", clientId);
      },
    },
  },
};
</script>
